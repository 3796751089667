$(window)
  .on("resize", function () {
    if ($(this).width() > 1200) {
      if (window.device == "mobile") {
        location.reload();
      }
      window.device = "desktop";
    } else {
      if (window.device == "desktop") {
        location.reload();
      }
      window.device = "mobile";
    }

    if ($(this).width() > 1100 && $(this).width() < 1400) {
      const notMac = navigator.userAgentData;

      if (notMac == undefined) {
        $("#landscape-lock").removeClass("hidden");
      }
    }
  })
  .trigger("resize");

$("#preload").delay(1000).fadeOut(600);

// lock right click
$(document).bind("contextmenu", function (event) {
  return false;
});

$(".scrolldown").on("click", function () {
  gsap.to(window, {
    duration: 1.2,
    scrollTo: $(window).height(),
    ease: "power2.inOut",
  });
});

$(".backtotop").on("click", function () {
  gsap.to(window, {
    duration: 1.2,
    scrollTo: 0,
    ease: "power2.inOut",
  });
});

$("[data-share]").each((i, el) => {
  var type = el.dataset.share;
  $(el).click(function (e) {
    e.preventDefault();

    var winHeight = 360;
    var winWidth = 600;
    var winTop = screen.height / 2 - winHeight / 2;
    var winLeft = screen.width / 2 - winWidth / 2;
    var url = $(this).attr("href");

    if (type == "facebook") {
      window.open(
        "https://www.facebook.com/sharer/sharer.php?u=" + url,
        "sharer",
        "top=" +
          winTop +
          ",left=" +
          winLeft +
          ",toolbar=0,status=0,width=" +
          winWidth +
          ",height=" +
          winHeight
      );
    } else if (type == "twitter") {
      window.open(
        "https://twitter.com/share?url=" + url,
        "sharer",
        "top=" +
          winTop +
          ",left=" +
          winLeft +
          ",toolbar=0,status=0,width=" +
          winWidth +
          ",height=" +
          winHeight
      );
    } else if (type == "pinterest") {
      window.open(
        "https://www.pinterest.com/pin/create/button/?url=" + url,
        "sharer",
        "top=" +
          winTop +
          ",left=" +
          winLeft +
          ",toolbar=0,status=0,width=" +
          winWidth +
          ",height=" +
          winHeight
      );
    } else if (type == "googleplus") {
      window.open(
        "https://plus.google.com/share?url=" + url,
        "sharer",
        "top=" +
          winTop +
          ",left=" +
          winLeft +
          ",toolbar=0,status=0,width=" +
          winWidth +
          ",height=" +
          winHeight
      );
    } else if (type == "linkedin") {
      window.open(
        "https://www.linkedin.com/cws/share?url=" + url,
        "sharer",
        "top=" +
          winTop +
          ",left=" +
          winLeft +
          ",toolbar=0,status=0,width=" +
          winWidth +
          ",height=" +
          winHeight
      );
    } else if (type == "weibo") {
      window.open(
        "https://service.weibo.com/share/share.php?url=" + url,
        "sharer",
        "top=" +
          winTop +
          ",left=" +
          winLeft +
          ",toolbar=0,status=0,width=" +
          winWidth +
          ",height=" +
          winHeight
      );
    } else if (type == "line") {
      window.open("https://line.naver.jp/R/msg/text/?" + url);
    } else if (type == "instagram") {
      window.open("https://www.instagram.com/?url=" + url);
    }
  });
});

$(".menu").on("click", function () {
  // gsap.to(window, {
  //   duration: 1.2,
  //   scrollTo: {
  //     y: $('footer'),
  //   },
  //   ease: "power2.inOut",
  // });
  $('.topmenu').addClass('is-show')
});
$(".topmenu .close").on("click", function () {
  // gsap.to(window, {
  //   duration: 1.2,
  //   scrollTo: {
  //     y: $('footer'),
  //   },
  //   ease: "power2.inOut",
  // });
  $('.topmenu').removeClass('is-show')
});



